export const NODE_ENV = process.env.NODE_ENV;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const VERSION = process.env.REACT_APP_VERSION;


export const LOCALES_SERVICE_URL = process.env.REACT_APP_LOCALES_SERVICE_URL;
export const PRODUCT_SERVICE_URL = process.env.REACT_APP_PRODUCT_SERVICE_URL;
export const CACHER_SERVICE_URL = process.env.REACT_APP_CACHER_SERVICE_URL;
export const INTERNET_SERVICE_URL = process.env.REACT_APP_INTERNET_SERVICE_URL;

export const ON_BOARD = "ON_VESSEL";

export const LOCAL_STORAGE_SHOPPING_BASKET = "shopping-basket-2p7fkVG8";