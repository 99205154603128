import React, { Suspense } from "react";
import Loader2 from "../../components/ui/app1/Loader";

export { default as App } from "./App";
export { default as Oops } from "./Oops";
export { default as AuthorizationPage } from "./AuthorizationPage";
export { default as Authentication } from "./Authentication";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader2 />}>
        <Component {...props} />
    </Suspense>
);

export const DevPage = Loadable(React.lazy(() => import('./DevPage')));
export const RootPage = Loadable(React.lazy(() => import('./RootPage')));
export const AlacartePage = Loadable(React.lazy(() => import('./AlacartePage')));
export const BeveragePage = Loadable(React.lazy(() => import('./BeveragePage')));
export const ExcursionPage = Loadable(React.lazy(() => import('./ExcursionPage')));
export const SushimenuPage = Loadable(React.lazy(() => import('./SushimenuPage')));
export const MenuPage = Loadable(React.lazy(() => import('./MenuPage')));
export const PersonaPage = Loadable(React.lazy(() => import('./PersonaPage')));
export const PhotoPage = Loadable(React.lazy(() => import('./PhotoPage')));
export const PlaygroundPage = Loadable(React.lazy(() => import('./PlaygroundPage')));
export const RoomPage = Loadable(React.lazy(() => import('./RoomPage')));
export const SchedulePage = Loadable(React.lazy(() => import('./SchedulePage')));
export const ServicePage = Loadable(React.lazy(() => import('./ServicePage')));
export const SpaPage = Loadable(React.lazy(() => import('./SpaPage')));
export const SurveysPage = Loadable(React.lazy(() => import('./SurveysPage')));
export const TVProgramPage = Loadable(React.lazy(() => import('./TVProgramPage')));
export const VesselRoutePage = Loadable(React.lazy(() => import('./VesselRoutePage')));
export const VesselSchemePage = Loadable(React.lazy(() => import('./VesselSchemePage')));
export const InternetPage2 = Loadable(React.lazy(() => import('./InternetPage2')));
export const InternetInfoPage = Loadable(React.lazy(() => import('./InternetInfoPage')));
export const ShoppingBasketPage = Loadable(React.lazy(() => import('./ShoppingBasketPage')));
export const TipsPage = Loadable(React.lazy(() => import('./TipsPage')));
export const PolicyPage = Loadable(React.lazy(() => import('./PolicyPage')));
export const PaymentPage = Loadable(React.lazy(() => import('./PaymentPage')));
export const PaymentSuccessPage = Loadable(React.lazy(() => import('./PaymentSuccessPage')));
export const PaymentFailPage = Loadable(React.lazy(() => import('./PaymentFailPage')));
export const ShorexPurchasePage = Loadable(React.lazy(() => import('./ShorexPurchasePage')));
export const ShorexPurchasedPage = Loadable(React.lazy(() => import('./ShorexPurchasedPage')));
