import React from "react";
import { Outlet } from "react-router";
import { ContextApp } from "../../../contexts/app1";
import { DataForm } from "../../../components/ui/app1/authorization/DataForm";

export default function Authentication(props) {
    const context = React.useContext(ContextApp);
    const { account } = context;

    if (account.value === undefined)
        return <></>;
        
    if (!(account.value) || !(account.value.persona))
        return (<div className="center content">
            <DataForm />
        </div>);

    return <>
        <Outlet />
    </>
}