import Oops from "../pages/Oops";
import app1Routes from "./app1";

app1Routes.path = "";

const appsRoutes = [
    app1Routes,
];

appsRoutes[0].errorElement = <Oops />;

export default appsRoutes;