import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { getAccount } from '../../../api/account/account';
import { ContextApp } from '../../../contexts/app1';
import '../../../assets/fonts/GoogleSans/stylesheet.css';
import '../../../styles/App.css';
import '../../../assets/fonts/GoogleSans/stylesheet.css';
import { getPreferences } from '../../../api/system';
import i18n from "../../../services/i18n";
import '../../../assets/fonts/GoogleSans/stylesheet.css';
import { LOCAL_STORAGE_SHOPPING_BASKET } from '../../../env';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
    typography: {
        fontFamily: 'Google Sans,arial,sans-serif',
        fontSize: 14,
    },
    palette: {
        primary: {
            light: '#27b9d1',
            main: '#27b9d1',
            dark: '#27b9d1',
            contrastText: '#fff',
        },
        secondary: {
            light: '#27b9d1',
            main: '#27b9d1',
            dark: '#27b9d1',
            contrastText: '#000',
        },
    },
});

function useLocalState() {
    const toAccount = React.useCallback(account => {
        if (account instanceof Error || account === null || (account instanceof Object && account.hasOwnProperty("id"))) {
            return account;
        }
        return null;
    }, []);

    const navigate = useNavigate();

    const toPurchases = React.useCallback(values => {
        if (Array.isArray(values) && values.length > 0) {
            let _value = values.filter(value => typeof value === 'object' && value.hasOwnProperty('kind') && value.hasOwnProperty('product'));
            return _value;
        }
        return [];
    }, []);

    const loadPurchases = React.useCallback(() => {
        let loadPurchases = localStorage.getItem(LOCAL_STORAGE_SHOPPING_BASKET);
        try {
            return toPurchases(JSON.parse(loadPurchases));
        } catch(e) {
            console.error(e);
        }
        return [];
    }, [toPurchases]);

    const _loadedPurchases = React.useMemo(() => {
        return loadPurchases();
    }, [loadPurchases]);

    const [ _account, _setAccount ] = React.useState();
    const [ _features, _setFeatures ] = React.useState();
    const [ _purchases, _setPurchases ] = React.useState(_loadedPurchases);
    const [ _sessionISA, _setSessionISA ] = React.useState();

    const setAccount = React.useCallback(value => {
        _setAccount(toAccount(value))
    }, [_setAccount, toAccount]);

    const setPurchases = React.useCallback(value => {
        value = toPurchases(value);
        localStorage.setItem(LOCAL_STORAGE_SHOPPING_BASKET, JSON.stringify(value));
        _setPurchases(value);
    }, [toPurchases]);

    const account = React.useMemo(() => {
        const account = {
            set: value => setAccount(value),
            value: _account,
        };
        return account;
    }, [_account, setAccount]);

    const features = React.useMemo(() => {
        const features = {
            value: _features,
        };
        return features;
    }, [_features]);

    const purchases = React.useMemo(() => {
        const purchases = {
            set: value => {
                setPurchases(value);
            },
            add: data => {
                if (Array.isArray(data)) {
                    for (let value of data) {
                        _purchases.push(value);
                    }
                } else if (typeof data === 'object') {
                    _purchases.push(data);
                }
                setPurchases([..._purchases])
            },        
            delete: id => {
                _purchases.splice(_purchases.findIndex(purchase => purchase.id === purchase.product), 1);
                setPurchases([..._purchases])
            },
            clear: () => {
                _purchases.splice(0, _purchases.length);
                setPurchases(_purchases);
            },
            value: _purchases,
        };
        return purchases;
    }, [_purchases, setPurchases]);

    React.useEffect(() => {
        const foo = event => {
            if (event.key === LOCAL_STORAGE_SHOPPING_BASKET) {
                setPurchases(loadPurchases());
            }
        };
        window.addEventListener("storage", foo);
        return () => window.removeEventListener("storage", foo);
    }, [loadPurchases, setPurchases]);


    const sessionISA = React.useMemo(() => {
        const sessionISA = {
            set: value => { _setSessionISA(value); },
            value: _sessionISA,
        };
        return sessionISA;
    }, [_sessionISA]);

    const context = React.useMemo(() => {
        const context = {
            account,
            features,
            purchases,
            sessionISA,
        };
        return context;
    }, [account, features, purchases, sessionISA]);
    
    const preferencesRef = React.useRef(false);
    React.useEffect(() => {
        if (preferencesRef.current)
            return;
        preferencesRef.current = true;
        getPreferences()
            .then(responce => {
                const features = responce instanceof Object && Array.isArray(responce.features)
                    ? responce.features.filter(feature => typeof feature === "string")
                    : [];
                _setFeatures(features);
            })
            .catch(e => _setFeatures(e));
    }, [context]);

    React.useEffect(() => {
        getAccount()
            .then(response => setAccount(response))
            .catch(e => setAccount(e));
    }, [navigate, setAccount]);

    return {
        context
    };
}

export default function App() {
    const state = useLocalState();
    const { context } = state;

    const { i18n } = useTranslation();

    React.useEffect(() => {
        if (i18n.language !== 'en' && i18n.language !== 'ru')
            i18n.changeLanguage('ru');
    }, [i18n]);
    
    return (
        <ContextApp.Provider value={context}>
            <ThemeProvider theme={theme}>
                <Outlet />
            </ThemeProvider>
        </ContextApp.Provider>
    );
}