
export function isString(value) {
    return (typeof value === 'string' || value instanceof String);
  }
  
  export const isNotEmpty = (value) => {
      return (typeof value === 'string' || value instanceof String) && value.trim() === '';
  }
  
  export function isNumber(value) {
      return (typeof value === 'number' || value instanceof Number);
  }
  
  export function isNotNaN(value) {
      return (typeof value === 'number' || value instanceof Number) && !isNaN(value);
  }
  
  export function isObject(value) {
      return (typeof value === 'object' || value instanceof Object);
  }
  
  export function isFunction(value)  {
      return (typeof value === 'function' || value instanceof Function);
  };
  
  export function isArray(value) {
      return Array.isArray(value);
  };
  
    
  /* DEV */
  
  const GLOBAL = {
      fooCounter: 0,
  };
  
  export function foo(callback) {
      const fooCounter = GLOBAL.fooCounter++;
      return function() {
          callback(...arguments);
      };
  }
  
  /* DATE */
  
  export function getDate(value) {
      if (typeof value === "object" && value instanceof Date)
          return value;
      if (typeof value === "string" || value instanceof String) {
          value = Date.parse(value);
          if (!isNaN(value))
              return new Date(value);
      }
      if (typeof value === "number" || value instanceof Number) {
          value = new Date(value);
          if (value instanceof Date && !isNaN(value))
              return value;
      }
      return new Date();
  }
  
  export function getTimestamp(value) {
      return getDate(value).getTime();
  }
  
  export function getUTCStart(value) {
      const UTC = getDate(value).toISOString().split("T")[0].split("-");
      return Date.UTC(parseInt(UTC[0]), parseInt(UTC[1]), parseInt(UTC[2]));    
  }
  
  String.prototype.format = function() {
      var formatted = this;
      for (var i = 0; i < arguments.length; i++) {
          var regexp = new RegExp('\\{'+i+'\\}', 'gi');
          formatted = formatted.replace(regexp, arguments[i]);
      }
      return formatted;
  };
  
  export function formatString(text, params) {
      if (isArray(params)) {
          for (var i = 0; i < params.length; i++) {
              text = text.replace(new RegExp('\\{' + i + '\\}', 'gi'), params[i]);
          }
      } else if (isObject(params)) {
          params = Object.entries(params);
          for (let param in params) {
              param = params[param];
              text.replace(new RegExp('\\{' + param[0] + '\\}', 'gi'), params[1]);
          }
      }
      return text;
  }

