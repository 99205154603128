import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import appsRoutes from './routes';
import { ContextApps } from './contexts';
import Oops from './pages/Oops';


const toPath = (path, parent) => {
    path = path.trim().replace("\\", "/");
    if (!path.startsWith("/")) {
        path = path.length ? `/${path}` : "/";
    }
    if (typeof parent !== "string") {
        return path;
    }
    if ((parent = parent.trim().replace("\\", "/")).endsWith("/")) {
        parent = parent.slice(0, parent.length - 1)
    }
    return `${parent}${path}`;
}

const toRoute = (route, parent, stack) => {
    stack.push(route);
    route._path = toPath(route.path, parent);
    const children = route.children ? toRoutes(route.children, route._path, stack) : undefined;
    return Array.isArray(children) && children.length
        ? { ...route, children } : { ...route };
};

const toRoutes = (routes, parent, stack) => {
    routes = (Array.isArray(routes)
        ? routes.length ? [ ...routes ] : [ ]
        : routes instanceof Object
            ? Object.values(routes)
            : [ ]);
    routes = routes.filter(route => route instanceof Object && typeof route.path === "string" && React.isValidElement(route.element))
    routes = routes.map(route => toRoute(route, parent, stack));
    return routes;
};

let stack = [];

function useRoutesState(apps) {
    const _toRoutes = React.useCallback(routes => {
        stack.forEach(route => delete route._path);
        console.debug("1. routes %o", routes);
        routes = toRoutes(routes, "", stack = []);
        console.debug("2. routes %o", routes);
        routes = Array.isArray(routes) && routes.length
            ? routes
            : [
                {
                    path: "*",
                    element: <Oops />
                }
            ];
        console.debug("3. routes %o", routes);
        return routes;
    }, []);
    
    const [ _routes, _setRoutes ] = React.useState(React.useMemo(() => _toRoutes(apps), [apps, _toRoutes]));

    const setRoutes = React.useCallback(routes => {
        _setRoutes(_toRoutes(routes));
    }, [_toRoutes, _setRoutes]);
    
    return React.useMemo(() => {
        const routes = {
            value: _routes,
            set: routes => setRoutes(routes),
        }
        return routes;
    }, [ _routes, setRoutes ]);
}

function useReportsState() {
    const [_reports, _setReports] = React.useState();
    
    const setReports = React.useCallback(reports => {
        _setReports(reports);
    }, []);

    return React.useMemo(() => {
        const reports = {
            value: _reports,
            set: reports => setReports(reports),
        }
        return reports;
    }, [_reports, setReports]);
}

export default function App() {
    const routes = useRoutesState(appsRoutes);
    const router = React.useMemo(() => createBrowserRouter(routes.value), [routes]);
    const reports = useReportsState();
    
    const context = React.useMemo(() => {
        return {
            routes,
            reports
        }
    }, [routes, reports]);

    return <ContextApps.Provider value={context}>
        <RouterProvider router={router} />
    </ContextApps.Provider>;
}