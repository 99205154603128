import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";

import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { LOCALES_SERVICE_URL } from "../env";

export const localization =
[
    {
        name:"ru-RU",
        language: "ru",
        countryCode: "RU",

    },
    {
        name: "en-US",
        language: "en",
        countryCode: "GB",
    }
];

i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackNS: ['translation', 'internet'],
        backend: {
            backends: [
                HttpBackend,
                resourcesToBackend((lng, ns) => {
                    const locales = import(`../locales/${lng}/${ns}.json`);
                    return locales;
                }),
            ],
            backendOptions: [{
                loadPath: `${LOCALES_SERVICE_URL}/{{lng}}/{{ns}}.json`
            }]
          }
    })
    .then(function(err, t) {
        //console.log('i18nnot FOUNT KEY %o %o', err, i18next.t('key'));
        //document.getElementById('output').innerHTML = i18next.t('key');
    });

export default i18next;