import { useEffect, useRef, useState } from "react";
import styles from './SliderButton.module.scss';
import { SvgIcon } from "@mui/material";
import { IconSwitchIn } from "../../../../assets/icons";

export default function SliderButton(props) {
    const {
        style,
        children,
        onChange,
        disable,
        innerElement,
    } = props;

    const refFrame = useRef(null);
    const refOuter = useRef(null);
    const refInner = useRef(null);
    const refBack = useRef(null);
    const refCaption = useRef(null);


    useEffect(() => {
        const _onChange = (event, val) => {
            onChange?.(event, val);
        }
        
        const blockOuter = refOuter?.current;
        const blockInner = refInner?.current;
        const blockBack = refBack?.current;
        const blockCaption = refCaption?.current;
        if (blockOuter === null || blockInner === null) 
            return;
        const blockInnerMousedown = (event) => {
            event.stopPropagation();
            
            let positionX = event.clientX;
            setStyleInner({...styleInner, position: 'absolute'});

            const move = (event) => {
                
                event.stopPropagation();
                const positionOuter = blockOuter.getBoundingClientRect();
                const positionInner = blockInner.getBoundingClientRect();
                const switchingBoundary = positionOuter.width / 2 - positionInner.width / 2;
                let x = blockInner.offsetLeft + (event.clientX - positionX);
                if (x < 0)
                    x = 0;
                else if (x > (positionOuter.width - positionInner.width))
                    x = positionOuter.width - positionInner.width;
                
                if (x > switchingBoundary)
                    blockCaption.style.color = '#27b9d1';
                else 
                    blockCaption.style.color = '#fff';
                blockInner.style.left = `${x}px`;
                blockBack.style.width = `${positionOuter.width - x}px`;
                positionX = event.clientX;
            };

            const up = (event) => {
                event.stopPropagation();
                const positionOuter = blockOuter.getBoundingClientRect();
                const positionInner = blockInner.getBoundingClientRect();
                const switchingBoundary = positionOuter.width / 2 - positionInner.width / 2;

                blockInner.style.left = null;
                if ((positionOuter.x + switchingBoundary) > (positionInner.x)) {
                    blockInner.style.left = '0';
                    blockInner.style.right = null;
                    blockBack.style.width = `${100}%`;
                } else {
                    blockInner.style.left = null;
                    blockInner.style.right = '0';
                    blockBack.style.width = `${positionInner.width}px`;
                    _onChange(event, true);
                }

                window.removeEventListener('mousemove', move);
                window.removeEventListener('mouseup', up);
            };

            window.addEventListener('mousemove', move);
            window.addEventListener('mouseup', up);
        }
        
        const blockInnerTouchstart = (event) => {
            event.stopPropagation();
            
            let positionX = event.clientX;
            setStyleInner({...styleInner, position: 'absolute'});

            const move = (event) => {
                event.stopPropagation();
                const positionOuter = blockOuter.getBoundingClientRect();
                const positionInner = blockInner.getBoundingClientRect();
                const switchingBoundary = positionOuter.width / 2 - positionInner.width / 2;
                let x = blockInner.offsetLeft + (event.touches[0].clientX - positionX);

                if (x < 0)
                    x = 0;
                else if (x > (positionOuter.width - positionInner.width))
                    x = positionOuter.width - positionInner.width;

                if (x > switchingBoundary)
                    blockCaption.style.color = '#27b9d1';
                else 
                    blockCaption.style.color = '#fff';
                blockInner.style.left = `${x}px`;
                blockBack.style.width = `${positionOuter.width - x}px`;
                positionX = event.touches[0].clientX;
            };

            const up = (event) => {
                event.stopPropagation();
                const positionOuter = blockOuter.getBoundingClientRect();
                const positionInner = blockInner.getBoundingClientRect();
                const switchingBoundary = positionOuter.width / 2 - positionInner.width / 2;

                if ((positionOuter.x + switchingBoundary) > (positionInner.x)) {
                    blockInner.style.left = '0';
                    blockInner.style.right = null;
                    blockBack.style.width = `${100}%`;
                } else {
                    blockInner.style.left = null;
                    blockInner.style.right = '0';
                    blockBack.style.width = `${positionInner.width}px`;
                    _onChange(event, true);
                }

                window.removeEventListener('touchmove', move);
                window.removeEventListener('touchend', up);
            };

            window.addEventListener('touchmove', move);
            window.addEventListener('touchend', up);
        }

        if (!!disable)
            return;

        const blockOuterClick = (event) => {
            
            event.stopPropagation();
            const positionOuter = blockOuter.getBoundingClientRect();
            const positionInner = blockInner.getBoundingClientRect();
            const switchingBoundary = positionOuter.width / 2 - positionInner.width / 2;

            blockInner.style.left = null;
            blockInner.style.right = '0';
            blockBack.style.width = `${positionInner.width}px`;
            blockCaption.style.color = '#27b9d1';

            _onChange(event, true);
        }

        const blockInnerClick = (event) => {
            event.stopPropagation();
        };

        blockInner.addEventListener('mousedown', blockInnerMousedown);
        blockInner.addEventListener("touchstart", blockInnerTouchstart);
        
        blockInner.addEventListener("click", blockInnerClick);
        blockOuter.addEventListener("click", blockOuterClick);
        
        return () => {
            blockInner.removeEventListener('mousedown', blockInnerMousedown);
            blockInner.removeEventListener("touchstart", blockInnerTouchstart);
            blockInner.removeEventListener("click", blockInnerClick);
            blockOuter.removeEventListener("click", blockOuterClick);
        }

    }, [ refFrame.current, refOuter.current, refInner.current, refBack.current, refCaption.current, disable, onChange ]);

    const [ styleDesc, setStyleDesc ] = useState(null);
    const [ styleBack, setStyleBack ] = useState(null);
    const [ styleInner, setStyleInner ] = useState(null);

    useEffect(() => {
        const blockFrame = refFrame.current;
        const blockOuter = refOuter.current;
        const blockInner = refInner.current;
        if (!blockOuter)
            return;

        const height = blockOuter.getBoundingClientRect().height;
        const heightInner = blockInner.getBoundingClientRect().height;
  
        const width = blockOuter.getBoundingClientRect().height;
        const widthOuter = blockOuter.getBoundingClientRect().width;

        setStyleDesc({
            ...styleDesc,
            marginBottom: `-${heightInner}px`,
        });

        setStyleBack({
            ...styleBack,
            height: `${height}px`,
            //width: `${widthOuter}px`,
        });

        setStyleInner({
            ...styleInner,
            height: isNaN(height) ? '1px' : `${height}px`,
            width: `${width}px`,
            borderRadius: blockFrame.style.borderRadius,
        });

    }, [ refFrame.current, refOuter.current, refInner.current, refBack.current, refCaption.current ]);

    return (<>
        <div
            ref={refFrame}
            style={{
                height: '40px',
                userSelect: 'none',
                ...style,
            }}
        >
            <div
                ref={refOuter}
                className={styles.outer}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    borderRadius: '15px',
                    border: '1px solid #27b9d1'
                }}
            >

                <div
                    ref={refBack}
                    style={{
                        top: 0,
                        position: 'absolute',
                        backgroundColor: '#27b9d1',
                        borderRadius: '15px',
                        right: 0,
                        ...styleBack,

                        width: '100%'
                    }}
                >
                </div>

                <div
                    ref={refCaption}
                    style={{
                        top: 0,
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        display: 'flex',
                        color: '#fff',
                        fontSize: '18px',
                        fontWeight: '500',
                        ...styleDesc,
                    }}
                >
                        <div style={{ margin: 'auto' }}>
                            {children}
                        </div>
                </div>      

                <div
                    ref={refInner}
                    style={{
                        position: 'absolute',
                        height: '70px',
                        width: '100%',
                        padding: '5px',
                        ...styleInner
                    }}
                >
                    <div
                        className={styles.inner}
                        style={{
                            background: '#fff',
                            width: '100%',
                            height: '100%',
                            borderRadius: '15px',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                weight: '100%',
                                display: 'flex',
                                paddingTop: '3px',
                                color: '#27b9d1',
                            }}
                        >
                            <div style={{ margin: 'auto' }} >
                                <SvgIcon
                                    component={IconSwitchIn}
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>


            </div>
        </div>
    </>);
}