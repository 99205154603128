import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useDataForm } from ".";
import { Trans, useTranslation } from "react-i18next";
import styles from "./DataForm.module.scss";
import { useEffect, useState } from "react";
import { imgLogo } from "../../../../../assets/imgs";
import { getPolicy } from "../../../../../api/rest";
import { ClosableDialogTitle } from "../../dialogs/ClosableDialogTitle";
import { useRef } from "react";
import { SliderButton } from "../../SliderButton";
import { postPersonaCheck } from "../../../../../api/account/account";
import { useNavigate } from "react-router";

function SliderButton1(props) {
    const {
        style,
        children,
        onChange,
    } = props;

    const [ disable, setDisable ] = useState(false);

    return (
        <SliderButton
            style={{
                width: '80%',
                height: '70px',
                ...style,
            }}
            onChange={(event, value) => {
                if (value) {
                    setDisable(true);
                    onChange(event, true);
                }
            }}
            disable={disable}
        >
            {children}
        </SliderButton>
    );    
}

export default function DataForm(props) {
    const {
        account,
        doChangeAccount,
        doChech,
        doLogin,
        error,
        setError,
        sending,
    } = useDataForm(props);
    
    const navigate = useNavigate();

    const [polition, setPolition] = useState(false);
    
    const { t, i18n } = useTranslation();

    const [policyDialog, setPolicyDialog] = useState(false);

    const [ disable, setDisable ] = useState(false);
    
    const divRef = useRef(null);

    const [dataForm, setDataForm] = useState({roomCode: "", documentNumber: ""});

    useEffect(() => {
        if (!policyDialog && divRef.current == null)
            return;
        getPolicy(i18n.language)
        .then((response) => {
            divRef.current.innerHTML = response;
        });   
    }, [divRef.current, policyDialog, i18n.language]);

    return (<>
        {policyDialog &&
            <Dialog
                open={true}
                onClose={()=>setPolicyDialog(false)}
            >
                <ClosableDialogTitle
                    onClose={()=>setPolicyDialog(false)}
                />
                <DialogContent>
                    <div style={{marginBottom:"20px"}}>
                        <Typography variant="h5" align="center">{t("Privacy policy general")}</Typography>
                    </div>
                    <div ref={divRef} />
                </DialogContent>
                
            </Dialog>}

        <div
            className={styles.ctx}
            style={{
                margin: "100px 0 0 0 "
            }}
        >
            <div
                style={{
                    width: "100%",
                    textAlign: "center",
                    verticalAlign: "middle",

                }}
                className="center"
            >
                <img
                    src={imgLogo}
                    rel="logo"
                    className="w-18 h-12 object-contain center"
                    alt=""
                    style={{
                        display: "inline-block",
                        width: "250px",
                    }}
                />
            </div>
            <form
                {...props} onSubmit={doLogin}
            >
                <div className={styles.wrap}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            fullWidth
                            component="h1"
                            variant="h5"
                            align="center"
                            sx={{
                                p: "0 40px 0 40px"
                            }}
                        >
                            {t("Enter your details to continue")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            InputProps={{
                                style: {
                                    borderRadius: "15px",
                                    border: 'none',
                                    backgroundColor: "#fff",
                                    height: '70px',
                                }
                            }}
                            sx={{border: 'none', "& fieldset": { border: 'none' },}}
                            placeholder={t("Cabin number")}
                            autoComplete="cabine"
                            value={ dataForm?.roomCode }
                            onChange={event =>  {
                                const value = event.target.value;
                                const data = {...dataForm, roomCode: value};

                                setDataForm(data);
                                //doChangeAccount(event, { ...account, roomCode: event.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            InputProps={{
                                style: {
                                    borderRadius: "15px",
                                    border: 'none',
                                    backgroundColor: "#fff",
                                    height: '70px',
                                }
                            }}
                            sx={{border: 'none', "& fieldset": { border: 'none' },}}
                            placeholder={t("4 Last digits of the passport")}
                            autoComplete="docno"
                            value={ dataForm?.documentNumber }
                            onChange={event => {
                                const value = event.target.value;
                                const data = {...dataForm, documentNumber: value};


                                setDataForm(data);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label={t("remember_me")} />
                    </Grid>

                    {((!polition) && !error) && (<>
                        <Grid
                            item xs={12}
                            style={{marginTop: '20px'}}
                        >
                            <SliderButton1
                                style={{
                                    textAlign:'center',
                                    margin: '0 auto',
                                }}
                                onChange={(event, value) => {
                                    postPersonaCheck(dataForm)
                                    .then(()=>setPolition(true))
                                    .catch(()=>setError(true))
                                }}
                            >
                                {t("Sign in")}
                            </SliderButton1>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                }}
                            >
                                <Button
                                    fullWidth
                                    elevation={0}
                                    variant="outlined"
                                    align='center'
                                    style={{
                                        width: '80%',
                                        height: '70px',
                                        textAlign:'center',
                                        margin: '0 auto',
                                        borderRadius: '15px',
                                        textTransform: 'none',
                                        fontSize: '18px',
                                    }}
                                    onClick={()=>{
                                        //window.location=routes.base._path
                                        navigate(-1)
                                    }}
                                >
                                    {t('Abort')}
                                </Button>
                            </div>
                        </Grid>
                    </>)}
                </Grid>


                </div>

                {(polition) &&
                    <div
                        className={`center content ${styles.ballon}`}
                        
                    >
                        <div>
                            <Typography
                                variant="h6"
                                align="center"
                            >
                                <span>{t("Data processing")}</span>
                            </Typography>                        
                            <Typography
                                align="center"
                            >
                                <Trans
                                    components={{
                                        b: <b />,
                                        i: <i />,
                                        br: <br/>,
                                        a: <span
                                            style={{color:'#27b9d1', cursor: 'pointer'}}
                                            onClick={_ =>setPolicyDialog(true)}
                                        />,
                                    }}
                                >
                                    {t("Data processing describe")}
                                </Trans>
                            </Typography>

                            <div
                                style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    margin: "40px 0 0 0",
                                }}
                            >
                                <SliderButton1
                                    style={{
                                        textAlign:'center',
                                        margin: '0 auto',
                                    }}
                                    onChange={(event, value) => {
                                        doLogin(event, dataForm)
                                        .then((result) => {
                                            navigate(0)
                                            //navigate(routes.person._path)
                                        });
                                    }}
                                >
                                    {t("Agree")}
                                </SliderButton1>
                            </div>
                        </div>
                    </div>
                }
                {(error) &&
                    <div
                        className={`center content ${styles.ballon}`}
                        
                    >
                        <Typography
                            variant="h6"
                            align="center"
                        >
                            <span>{t("Error")}</span>
                        </Typography>                        
                        <Typography
                            align="center"
                        >
                            <span>{t("Incorrect authentication data.")}</span>
                        </Typography>

                        <div
                            style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                                margin: "40px 0 0 0",
                            }}
                        >
                            <Button
                                fullWidth
                                elevation={0}
                                variant="outlined"
                                style={{
                                    display: "inline-block",
                                    width: "80%",
                                    height: "56px",
                                    borderRadius: "10px"
                                }}
                                sx={{
                                    boxShadow: 0,
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "#cce2e6",
                                        boxShadow: 0,
                                    },
                                }}
                                onClick={(e) => {
                                    setError(false)
                                }}
                            >
                                {t("Try again")}
                            </Button>
                        </div>
                    </div>
                }

            </form>
        </div>
    </>);
}