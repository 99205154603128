import axios from "axios";
import { $authHost, $host } from "..";
import { isNumber, isObject, isString } from "../../tools";
import { PERSONA_LOGIN_URL } from ".";

export const login = async function (account) {
    const { data } = await $host.post(`/login`, account);
    return data;
}

export const loginPersona = async function (formdata) {
    const response = await axios({
        method: "POST",
        url: PERSONA_LOGIN_URL,
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
            'X-XSRF-TOKEN': window.getCsrfToken()
        },
    });
    if (response.status === 200)
        return response.data;
    throw new Error("Error authorization");
}

export const logout = async function () {
    const { data } = await $authHost.get(`/logout`);
    return data;
}

export const getAccount = async function (id) {
    const { data } = (isNumber(id) && !isNaN(id))
        ? await $host.get(`/account/${id}`)
        : await $host.get(`/account`);
    if (isObject(data))
        return data;
    return null;
}

export const getAccounts = async function (query) {
    const { data } = await $authHost.get(`/account/accounts`, { params: query });
    return data;
}

export const createAccount = async function (query) {
    const { data } = await $authHost.post(`/account`, query);
    return data;
}

export const updateAccount = async function (id, query) {
    const { data } = await $authHost.put(`/account/${id}`, query);
    return data;
}

export const getRole = async function (id) {
    const { data } = await $authHost.get(`/account/role/${id}`);
    return data;
}

export const getRoles = async function (query) {
    const { data } = await $authHost.get(`/account/roles`, { params: query });
    return data;
}

export const getAccessObjects = async function () {
    const { data } = await $authHost.get(`/account/accessesobjects`);
    return data;
}

export const getTransactions = async function () {
    const { data } = await $authHost.get(`/persona/transactions`);
    return data;
}

export const postPersonaCheck = async function (param) {
    const { data } = await $host.post(`/persona/check`, param);
    return data;
}

export const postPurchase2 = async function (param) {
    const { data } = await $authHost.post(`/payment/init`, param);
    return data;
}

export const getRates = async function () {
    const { data } = await $authHost.get(`/payment/rate`);
    return data;
}

export const getAmounts = async function () {
    const { data } = await $authHost.get(`/payment/amounts`);
    if (Array.isArray(data) && data.length > 0 && !isNaN(Number(data[0]))) {
        if (data[0] < 0) {
            data[1] = data[1] + data[0];
            if (data[1] < 0) {
                data[0] = data[1];
                data[1] = 0;
            } else {
                data[0] = 0;
            }
        }
        data[0] = Math.floor(data[0] * 100) / 100;
        data[1] = Math.floor(data[1] * 100) / 100;
    }
    return data;
}

export const getPaymants = async function (order_id) {
    const { data } = await $authHost.get(`/payment/payment/${order_id}`);
    return data;
}


