import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DataForm from '../../../components/ui/app1/authorization/DataForm/DataForm';
import '../../../styles/App.css';

export default function AuthorizationPage(props) {
    const handleSubmit = (event) => {
        event.preventDefault();
        //const data = new FormData(event.currentTarget);
    };

    return (<>
        <div
            className="center content"
        >
            <DataForm />
        </div>
    </>);
}