import { useContext, useState } from "react";
import { ContextApp } from "../../../../../contexts/app1";
import { loginPersona, postPersonaCheck } from "../../../../../api/account/account";
 
export function useDataForm(props) {
    const [account, setAccount] = useState();
    const [error, setError] = useState(false);
    const context = useContext(ContextApp);
    //const [contextAccount, setContextAccount] = context.account;
    const [sending, setSending] = useState(false);

    return {
        contextAccount: context.account.value,
        account: account,
        doChangeAccount: (_, account) => {
            setAccount(account);
            setError(false);
        },
        doChech: async (_, account2) => {
            setSending(true);
            const data = new FormData();
            data.append("documentNumber", account2?.documentNumber);
            data.append("roomCode", account2?.roomCode);
            try {
                const response = await postPersonaCheck(account2);
                setSending(false);
                return response;
            } catch (e) {
                setError(true);
                setSending(false);
                throw e;
            }
        },
        doLogin: async (_, account2) => {
            setSending(true);
            const data = new FormData();
            data.append("documentNumber", account2?.documentNumber);
            data.append("roomCode", account2?.roomCode);
            try {
                const response = await loginPersona(data);
                context.account.set(response);
                setSending(false);
                return response;
            } catch (e) {
                setError(true);
                setSending(false);
                throw e;
            }
        },
        error,
        setError,
        sending,
    };
}