import {
    App,
    Oops,
    DevPage,
    AuthorizationPage,
    Authentication,
    RootPage,
    PlaygroundPage,
    SchedulePage,
    SushimenuPage,
    MenuPage,
    ServicePage,
    TVProgramPage,
    VesselSchemePage,
    AlacartePage,
    BeveragePage,
    SpaPage,
    RoomPage,
    ExcursionPage,
    PhotoPage,
    VesselRoutePage,
    SurveysPage,
    PersonaPage,
    InternetPage2,
    InternetInfoPage,
    ShoppingBasketPage,
    PolicyPage,
    PaymentPage,
    PaymentSuccessPage,
    PaymentFailPage,
    TipsPage,
    ShorexPurchasePage,
    ShorexPurchasedPage,
} from "../../pages/app1";

export const app1RoutesProtected = {
    person: {
        path: "person",
        element: <PersonaPage />,
    },
    payment: {
        path: "payment",
        element: <PaymentPage />,
    },
    tips: {
        path: "tips",
        element: <TipsPage />,
    },
    shorex: {
        path: "shorexpurchase",
        element: <ShorexPurchasePage />,
    },
    shorexpurchased: {
        path: "shorexpurchased",
        element: <ShorexPurchasedPage />,
    },
}

export const app1Routes0 = {
    oops: {
        path: "*",
        element: <Oops />,
    },
    debug:{
        path: "debug",
        element: <DevPage />,
    },
    base: {
        path: "",
        element: <RootPage />,
    },
    protected: {
        path: "",
        element: <Authentication />,
        children: app1RoutesProtected,
    },
    account: {
        path: "login",
        element: <AuthorizationPage />,
    },
    menu: {
        path: "menus",
        element: <MenuPage />,
    }, 
    services: {
        path: "services",
        element: <ServicePage />,
    },
    alacarte: {
        path: "alacarte",
        element: <AlacartePage />,
    },
    excursion: {
        path: "excursion",
        element: <ExcursionPage />,
    },    
    beverage: {
        path: "cocktails",
        element: <BeveragePage />,
    },   
    spa: {
        path: "spa",
        element: <SpaPage />,
    },   
    room: {
        path: "roomservice",
        element: <RoomPage />,
    },
    photos: {
        path: "photos",
        element: <PhotoPage />,
    },
    playground: {
        path: "astorialand",
        element: <PlaygroundPage />,
    },
    sushimenu: {
        path: "sushimenu",
        element: <SushimenuPage />,
    },    
    project: {
        path: ":project",
        element: <></>
    },
    tvprogram: {
        path: "tvprogram",
        element: <TVProgramPage />,
    },
    vesselscheme: {
        path: "vesselscheme",
        element: <VesselSchemePage />,
    },
    schedule: {
        path: "schedule",
        element: <SchedulePage />,
    },
    vesselroute: {
        path: "vesselroute",
        element: <VesselRoutePage />,
    },
    surveys: {
        path: "surveys",
        element: <SurveysPage />,
    },
    internet: {
        path: "internet",
        element: <InternetPage2 />,
    },
    internetinfo: {
        path: "internetinfo",
        element: <InternetInfoPage />,
    },
    shoppingbasket: {
        path: "shoppingbasket",
        element: <ShoppingBasketPage />,
    },
    payment_success: {
        path: "payment_success/:order",
        element: <PaymentSuccessPage />,
    },
    payment_fail: {
        path: "payment_fail/:order",
        element: <PaymentFailPage />,
    },
    policy: {
        path: "policy",
        element: <PolicyPage />,
    },
}

export const app1Routes = {
    element: <App />,
    children: app1Routes0,
    errorElement: <Oops />,
};

export default app1Routes;