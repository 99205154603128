import axios from "axios";
import { $cacherHost, $host } from "./index";
import { isString } from "../tools";

String.prototype.format = function() {
    var formatted = this;
    for (var i = 0; i < arguments.length; i++) {
        var regexp = new RegExp('\\{'+i+'\\}', 'gi');
        formatted = formatted.replace(regexp, arguments[i]);
    }
    return formatted;
};

export const getCruiseInfo = async () => {
    const { data } = await $cacherHost.get(`/vessel_prepared`);
    return data;
}

export const getCruiseInfo2 = async () => {
    const { data } = await $cacherHost.get(`/cruise`);
    return data;
}

export const fetchSignalK = async () => {
    const { data } = await $cacherHost.get('/signalk');
    return data;
}

export const fetchSignalKOrigin = async () => {
    const { data } = await axios.get('http://10.101.130.51:3000/signalk/v1/api/vessels/self');
    return data;
}

export const getPolicy = async (language) => {
    if (isString(language)) {
        const { data } = await $host.get(`/privacypolicy?language=${language}`);
        return data;
    } else {
        const { data } = await $host.get(`/privacypolicy`);
        return data;
    }
}

export const getSurveysGreetings = async (language) => {
    if (isString(language)) {
        const { data } = await $host.get(`/surveysgreetings?language=${language}`);
        return data;
    } else {
        const { data } = await $host.get(`/surveysgreetings`);
        return data;
    }
}

export const getReviewAvailible = async () => {
    const { data } = await $host.get(`/review/availible`);
    return data;
}

const CATEGORIES_BASE = "/{0}/category";

export const getFullCategories = async (project) => {
    const { data } = await $host.get(CATEGORIES_BASE.format(project));
    return data;
}

const PROJECT = "/project/{0}";
const PROJECTS = "/project";

export const getProject = async (project) => {
    const { data } = await $host.get(PROJECT.format(project));
    return data
}

export const getProjects = async () => {
    const { data } = await $host.get(PROJECTS.format());
    return data;
}

function syncRequest(url) {
    var Httpreq = new XMLHttpRequest();
    Httpreq.open("GET", url, false);
    Httpreq.send(null);
    return Httpreq.responseText;
}

export const callMarker = async (url) => {
    const { data } = await $host.get(`/marker?url=${url}`);
    return data;
}