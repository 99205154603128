import React from "react";
import classes from "./index.module.css";

const Loader1 = (props) => {
    const { className } = props;
    return (
        <div
            className={className
                ? `${classes.loader_roller} ${className}`
                : classes.loader_roller
        }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const Loader2 = (props) => {
    const { className } = props;
    return (
        <div
            className={className
                ? `${classes.loader_ellipsis} ${className}`
                : classes.loader_ellipsis
        }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Loader2;