import { $host } from "..";

export const getPreferences = async () => {
    const responce = await $host.get(`/`);
    return responce.data;
}

export const getSetups = async function () {
    const { data } = await $host.get(`/setups`);
    return data;
}
