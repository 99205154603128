import axios from "axios";
import {
    PRODUCT_SERVICE_URL,
    CACHER_SERVICE_URL,
    INTERNET_SERVICE_URL
} from "../env";

const $host = axios.create({
    baseURL: PRODUCT_SERVICE_URL
});

const $authHost = axios.create({
    baseURL: PRODUCT_SERVICE_URL
});

const $cacherHost = axios.create({
    baseURL: CACHER_SERVICE_URL
});

const $internetHost = axios.create({
    baseURL: INTERNET_SERVICE_URL
});

export {
    $host,
    $authHost,
    $cacherHost,
    $internetHost
}

export * from './rest';